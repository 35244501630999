<template>
  <div class="DuPontAnalysis">
    <div :style="`width: ${width}px`">
      <item
        :name="dubang[0].name"
        :desc="dubang[0].desc"
        :value="dubang[0].value"
        down="1"
      />
    </div>
    <div :style="`width: ${width * 4 + rule * 3}px; justify-content: space-between;`">
      <item :name="dubang[1].name" :value="dubang[1].value" up="1" down="1" right="-" />
      <item :name="dubang[2].name" :value="dubang[2].value" up="1" right="-" />
      <item :name="dubang[3].name" :value="dubang[3].value" up="1" right="-" />
      <item :name="dubang[4].name" :value="dubang[4].value" up="1" />
      <div :style="`width: ${width * 3 + rule * 3}px;`" class="line"></div>
    </div>
    <div
      class="bt"
      :style="`width: ${width * 4 + rule * 3}px; justify-content: space-between;`"
    >
      <item :name="dubang[5].name" :value="dubang[5].value" up="1" right="* (1-" />
      <item :name="dubang[6].name" :value="dubang[6].value" up="1" right=") * (1+" />
      <item :name="dubang[7].name" :value="dubang[7].value" up="1" right=") /" />
      <item :name="dubang[8].name" :value="dubang[8].value" up="1" down=" ) / " />
      <div :style="`width: ${width * 3 + rule * 3}px;`" class="line"></div>
    </div>
    <div
      class="bt"
      :style="`width: ${width * 6 + rule * 5}px; justify-content: space-between;`"
    >
      <item :name="dubang[9].name" :value="dubang[9].value" up="1" left="360/ (" />
      <item :name="dubang[10].name" :value="dubang[10].value" up="1" left="+" />
      <item :name="dubang[11].name" :value="dubang[11].value" up="1" left="-" />
      <item :name="dubang[12].name" :value="dubang[12].value" up="1" left="+" />
      <item :name="dubang[13].name" :value="dubang[13].value" up="1" left="-" />
      <item :name="dubang[14].name" :value="dubang[14].value" up="1" left=" ) *" right=" ) " />
      <div :style="`width: ${width * 6 + rule * 3.1}px;`" class="line"></div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    item: require("./item").default,
  },
  props: {
    dubang: Array,
  },
  data() {
    return {
      width: 150,
      rule: 80,
    };
  },
};
</script>
<style lang="less" scoped>
.DuPontAnalysis {
  min-height: 400px;
  margin-top: 20px;

  > div {
    margin: 0 auto;
    display: flex;
    margin-bottom: 40px;
    position: relative;
    .line {
      position: absolute;
      left: 50%;
      top: -20px;
      height: 1px;
      width: 100%;
      background: #2b92fa;
      transform: translateX(-50%);
    }
  }
}
</style>

<template>
  <div id="DataEntry">
    <div class="title">
      <img
        src="@/assets/img/head/icon.png"
        style="margin-bottom: 5px; margin-right: 5px"
      />
      基本信息
    </div>
    <div class="content">
      <div
        class="row"
        style="
          justify-content: space-between;
          box-sizing: border-box;
          padding-bottom: 20px;
        "
      >
        <div class="row">
          <div class="row" id="some-element1">
            <div class="lable">预测年份：</div>
            <div class="left">
              <el-select
                size="medium"
                @change="set_subjects"
                v-model="form_data.year"
                style="width: 120px"
              >
                <el-option
                  v-for="year in year_list"
                  :value="year"
                  :key="year"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="row">
            <div class="lable" style="margin-left: 20px">预测类型：</div>
            <div class="left">
              <el-select
                size="medium"
                @change="set_subjects"
                v-model="form_data.report_type"
                style="width: 120px"
              >
                <el-option
                  v-for="(year, key) in report_type_mapping"
                  :value="key"
                  :key="year"
                  :label="year"
                ></el-option>
              </el-select>
            </div>
          </div>
          <!-- <div style="margin-left: 20px; display: flex; align-items: center">
            <div class="lable">企业名称：</div>
            <div class="left">
              <el-input
                style="width: 250px"
                disabled
                v-model="form_data.company_name"
              ></el-input>
            </div>
          </div> -->
          <div class="row">
            <div class="lable" style="margin-left: 20px">单位：</div>
            <div style="width: 80px">
              <el-select
                size="medium"
                @change="set_subjects"
                v-model="form_data.unit"
                style="width: 120px"
              >
                <el-option :value="'one'" label="元"></el-option>
                <el-option :value="'ten-thousand'" label="万元"></el-option>
              </el-select>
            </div>
          </div>
        </div>
      </div>
      <el-table
        stripe
        :header-cell-style="{ background: '#E9F3FF', color: '#333' }"
        :data="getTableData(subjects.data)"
        style="font-size: 16px"
        id="some-element2"
      >
        <el-table-column
          v-for="(item, i) in subjects.title"
          :key="item"
          :label="item"
        >
          <div slot-scope="{ row, $index }">
            <div v-if="i == 0">
              {{ row[item] }}
              <el-tooltip placement="top" v-if="toast[row[subjects.title[0]]]">
                <div slot="content">
                  <div style="pading: 10px">
                    <div style="margin-bottom: 10px">
                      <div style="margin-bottom: 10px">1、定义</div>
                      <div>{{ toast[row[subjects.title[0]]].定义 }}</div>
                    </div>
                    <div style="margin-bottom: 10px">
                      <div style="margin-bottom: 10px">2、公式</div>
                      <div>{{ toast[row[subjects.title[0]]].公式 }}</div>
                    </div>
                    <div>
                      <div style="margin-bottom: 10px">3、来源</div>
                      <div>{{ toast[row[subjects.title[0]]].来源 }}</div>
                    </div>
                  </div>
                </div>
                <i class="el-tooltip el-icon-warning-outline item"></i>
              </el-tooltip>
            </div>
            <div v-else>
              <el-input
                class="input"
                @input="
                  () => {
                    setInputColor(`${item}${i}${$index}`);
                  }
                "
                :id="`${item}${i}${$index}`"
                v-model="row[item]"
                size="small"
              ></el-input>
            </div>
          </div>
        </el-table-column>
      </el-table>
      <div class="desc" style="margin-top: 20px">
        <div>说明：</div>
        <div>
          1、资金测算功能是根据企业提供的财务报表数据，预测未来满足企业日常经营周转所需的流动资金贷款需求量。
        </div>
        <div>
          2、预测需过去3年财务数据，若数据有缺失，则无法进行正常下一步测算。
        </div>
        <div>
          3、若以上科目数据缺失或人为删除后未补全，则无法进行正常下一步测算。
        </div>
      </div>
    </div>
    <div class="buttom">
      <div>
        <el-button
          id="some-element3"
          style="width: 112px"
          type="primary"
          @click="next"
          >下一步</el-button
        >
      </div>
    </div>
    <el-dialog
      title="温馨提示"
      :visible.sync="dialogVisible"
      width="30%"
      style="margin-top: 10%"
      :before-close="handleClose"
    >
      <div style="text-align: center; font-size: 14px">
        是否确认跳过新手操作指引教程吗？
      </div>
      <div style="text-align: center; font-size: 14px">
        <el-radio @change="changes" label="true" v-model="radio"
          >以后不再展示教程</el-radio
        >
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="checks">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
// import Guide from 'vue-guide';

import driverStep from "./driver.js";
import Driver from "driver.js";

import { FinWhitewashCal, driverget, driverpost } from "@/api.js";
import { rating, calculateHigh } from "@/api/common";
import * as sesstion from "@/store/localStorage";

import { subjects } from "@/api/CapitalCalculation.js";

export default {
  components: {
    DuPontAnalysiss: require("./CapitalCalculation/DuPontAnalysis.vue").default,
  },

  // async mounted() {
  //   await Guide({
  //     // 目标dom元素
  //     elements: [
  //       {
  //         target: "#some-element1", // required: true,   目标元素: 支持id ,class, ref 等
  //         text: "选择想要预测的年份，下方会展示预测模型中所用到的财务数据，通常为预测年份前3年的财务数据。", // 弹窗主文本内容
  //         placement: "left-bottom", // 弹窗方向位置 left-top | left-bottom | right-top | right-bottom | top-left | top-right | bottom-left | bottom-right
  //         // image: 'https://www.matools.com/img/home/gif/default_gif.gif', // 弹窗img | gif
  //         dialogWidth: 622, // 弹窗宽度  默认值 322
  //         // 标识
  //         sign: {
  //           show: true, // boolean 控制弹窗内是否显示标识   默认值 false
  //           text: "操作指引", // any 弹窗内的标识内容  默认值  小贴士
  //         },
  //       },
  //       {
  //         target: "#some-element3", // required: true,   目标元素: 支持id ,class, ref 等
  //         text: "选择想要预测的年份，下方会展示预测模型中所用到的财务数据，通常为预测年份前3年的财务数据。", // 弹窗主文本内容
  //         placement: "left-bottom", // 弹窗方向位置 left-top | left-bottom | right-top | right-bottom | top-left | top-right | bottom-left | bottom-right
  //         // image: 'https://www.matools.com/img/home/gif/default_gif.gif', // 弹窗img | gif
  //         dialogWidth: 622, // 弹窗宽度  默认值 322
  //         // 标识
  //         sign: {
  //           show: true, // boolean 控制弹窗内是否显示标识   默认值 false
  //           text: "操作指引", // any 弹窗内的标识内容  默认值  小贴士
  //         },
  //       },

  //     ],
  //     // 配置项
  //     config: {
  //       // 按钮类型支持
  //       btn: {
  //         pre: {
  //           type: "text", // 按钮类型 text, default, plaim, danger, primary   默认值 default
  //           text: "上一步", //  默认值 上一条
  //         },
  //         next: {
  //           type: "primary", // 默认值 default
  //           text: "下一步", // 默认值 上一条
  //         },
  //         confirm: {
  //           type: "danger", // 默认值 default
  //           text: "我知道啦", // 默认值 知道啦
  //         },
  //       },
  //       // 遮罩颜色 默认值 rgba(0, 0, 0, 0.3)
  //       mask: {
  //         r: 0,
  //         g: 0,
  //         b: 0,
  //         a: 0.3,
  //       },
  //       // 目标dom元素
  //       element: {
  //         // 边框颜色  默认值 rgba(33, 33, 33, 0.3)
  //         borderColor: {
  //           r: 33,
  //           g: 33,
  //           b: 33,
  //           a: 0.1,
  //         },
  //         borderRadius: "4px", // 边框 角  默认值 '4px'
  //       },
  //       // 弹窗
  //       dialog: {
  //         // 背景色 默认值 rgba(255, 255, 255, 1)
  //         bgColor: {
  //           r: 0,
  //           g: 0,
  //           b: 0,
  //           a: 0,
  //         },
  //         fontColor: "#fff", // 字体颜色 默认值 #000
  //       },
  //     },
  //   });
  // },
  data() {
    return {
      dialogVisible: false,
      radio: false,
      year_list: [],
      form_data: {
        year: "",
        company_name: "",
        unit: "",
        company_code: "",
      },
      report_type_mapping: {
        "00": "合并报表",
        "01": "母公司报表",
      },
      report_type: "00",
      subjects: {
        data: [],
        title: [],
      },
      toast: {
        应收账款: {
          定义: "指应收账款净额。",
          公式: "应收账款原值-应收账款坏账准备",
          来源: "公司财务报表",
        },
        预付款项: {
          定义: "指预付款项净额。",
          公式: "预付款项原值-预付款项坏账准备",
          来源: "公司财务报表",
        },
        存货: {
          定义: "指存货净额。",
          公式: "存货原值-存货跌价准备",
          来源: "公司财务报表",
        },

        营运资金量: {
          定义: "指不考虑其他已有融资的情况下，企业为满足未来正常的营运需求所需要的资金量。",
          公式: "营运资金需求量=当年销售收入*（1-当年销售利润率）*（1+预计销售收入年增长率）/营运资金周转次数",
          来源: "模型测算",
        },
        流动资金贷款额度: {
          定义: "指考虑其他已有融资的情况下，企业为满足未来正常的运营时的资金缺口。",
          公式: "新增流动资金贷款额度=营运资金需求量-自有资金-现有流动资金贷款-其他渠道提供的营运资金 ",
          来源: "模型测算",
        },
        资金测算: {
          定义: "根据企业提供的财务报表数据，预测未来满足企业日常经营周转所需的流动资金贷款需求量",
          公式: "",
          来源: "",
        },
      },
    };
  },
  mounted() {
    this.getdriver();
  },
  created() {
    if (this.$route.query.status == 1) {
      this.setHistoryData();
      this.set_year_list();
      // console.log(2222)
    } else {
      this.set_form_data();
      this.set_year_list();
      // history
      this.set_subjects();
      // console.log(11111)
    }
    // console.log(this.form_data)
    this.$VM.DataEntry = this;
  },

  methods: {
    changes() {},

    //获取用户指引状态
    getdriver() {
      driverget().then((res) => {
        if (res.data.is_help == false) {
        } else {
          this.start();
        }
      });
    },
    //指引状态贮存
    checks() {
      let flag = this.radio;
      // console.log(flag);
      if (flag == "true") {
        driverpost().then((res) => {
          this.$message.success(res.data.msg);
          this.dialogVisible = false; //关闭对话框
        });
      } else {
        alert("请选择状态后点击确认按钮");
      }
    },

    // 方法
    start() {
      this.$nextTick(() => {
        const driver = new Driver({
          allowClose: false, // 是否允许点击遮罩时关闭 Whether clicking on overlay should close or not
          opacity: 0.3,
          doneBtnText: "我知道了", // Text on the final button
          closeBtnText: "跳过", // Text on the close button for this step
          stageBackground: "#fff", // Background color for the staged behind highlighted element
          nextBtnText: "下一步", // Next button text for this step
          prevBtnText: "上一步", // Previous button text for this step
          onDeselected: (Element) => {
            this.dialogVisible = true; //弹出对话框
          }, // 取消选择元素时调用
        });
        driver.defineSteps(driverStep);
        driver.start();
      });
    },

    search(param) {
      const company = sesstion.getLocalData("company");
      FinWhitewashCal({
        ...param,
        Stock_code: company.S_or_B_code ? company.S_or_B_code : "",

        company_name: company.S_or_B_code
          ? company.companyInfo.name
          : company.name,
      }).then((res) => {
        let safety_watch = res.data.safety_watch;
        let gaugeName = res.data.horsepower_watch;
        rating({
          year: param.year,

          report_time: param.report_time,
          company_name: param.name || param.company_name,
          company_code: param.company_code,
          safety_value: safety_watch,
          truth_value: gaugeName,
        }).then((res) => {
          setTimeout(() => {
            let reasonReportMapping = {
              "00": "年报",
              "01": "三季报",
              "02": "半年报",
              "03": "一季报",
              "001": "一月报",
              "002": "二月报",
              "003": "三月报",
              "004": "四月报",
              "005": "五月报",
              "006": "六月报",
              "007": "七月报",
              "008": "八月报",
              "009": "九月报",
              "010": "十月报",
              "011": "十一月报",
              "012": "十二月报",
            };
            this.$store.commit(
              "setYears",
              `(${param.year}${reasonReportMapping[param.report_time]})`
            );

            this.$VM.CompanyDetail.generalEvaluation = res.data.rating;
            sessionStorage.generalEvaluation = res.data.rating;
          }, 0);
        });
      });
    },
    setHistoryData() {
      const { CapitalCalculation } = this.$store.state;
      var form_data = {
        report_type: this.$VM.PublicSearch.form_data.report_type,
      };
      Object.keys(this.form_data).map((key) => {
        form_data[key] = CapitalCalculation[key];
      });
      this.form_data = form_data;

      this.subjects = CapitalCalculation.subjects;
    },
    setInputColor(id, border = `1px solid #d6dde4`) {
      $(`#${id}`).css({
        border: border,
      });
    },
    set_subjects() {
      subjects({ ...this.form_data }).then(({ data }) => {
        this.subjects = data;
      });
    },
    getTableData(list) {
      const data = [];
      list.map((item) => {
        if (!item.hidden) data.push(item);
      });
      return data;
    },
    set_form_data() {
      const company = sesstion.getLocalData("company");

      const form_data = {
        year: new Date().getFullYear(),
        company_name:
          (company.companyInfo && company.companyInfo.name) || company.name,
        unit: "ten-thousand",
        company_code: company.company_code,
        report_type: "00",
      };
      this.form_data = form_data;
    },
    set_year_list() {
      var year_list = [];
      var year = new Date().getFullYear();
      for (let i = 0; i < 6; i++) {
        year_list.push(year--);
      }
      this.year_list = year_list;
    },
    check() {
      var message = "";
      const title = this.subjects.title[0];
      this.subjects.data.map((row, $index) => {
        this.subjects.title.map((key, i) => {
          if (i > 0 && row[key] == "") {
            message += `${key}${row[title]}、`;
            this.setInputColor(`${key}${i}${$index}`, `1px solid #fdbfb8`);
          }
        });
      });
      if (message.length > 0) {
        message = "请先补充完善好相关数据";
      }
      return message;
    },
    financing_history() {},
    next() {
      const message = this.check();

      if (message.length > 0) return this.$message.error(message);

      this.$store.commit("setDataEntry", {
        ...this.form_data,
        subjects: this.subjects,
      });

      this.$store.commit("setCapitalCalculation", {
        ...this.form_data,
        subjects: this.subjects,
      });
      this.financing_history();
      // this.$router.push({
      //   name: ,
      // });
      this.$router.replace("CalculationResults");
    },
  },
};
</script>
<style lang="less" scoped>
.lable {
  color: #333;
  font-size: 14px;
}

#some-element2 {
  color: #333;
  font-size: 14px;
}

.input {
  color: #333;
  font-size: 14px;
}

#DataEntry {
  margin-top: 20px;
  background: #fff;
  .title {
    padding: 15px;
    font-size: 18px;
    font-weight: 550;
  }
  .item {
    color: dodgerblue;
  }

  > .content {
    background: #fff;
    padding: 0px 20px 20px 20px;
  }

  margin-bottom: 100px;

  .row {
    align-items: center;
  }

  .desc {
    font-size: 14px;
    color: #333;
    margin: 20px 0;
  }

  .buttom {
    width: 100%;
    background: #fff;
    // position: fixed;
    padding-right: 40px;
    bottom: 0;
    left: 0;
    height: 60px;
    text-align: right;
    line-height: 60px;
  }

  .el-dialog__footer {
    .dialog-footer {
      display: flex;
      justify-content: center;

      .el-button {
        width: 140px;
        height: 30px;
        line-height: 6px;
      }
    }
  }
}
</style>

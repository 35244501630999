<template>
  <div id="CalculationResults">
    <div class="form_data test1" id="some-element4">
      <div style="display: flex; align-items: center">
        <div class="lable">
          其他渠道提供的营运资金：<span class="year"
            >({{ form_data.year - 1 }}年)</span
          ><span style="color: #e02020; font-size: 18px">*</span>
        </div>
        <div class="left">
          <el-tooltip placement="top">
            <div slot="content">
              <div style="pading: 10px">
                <div style="margin-bottom: 10px">
                  <div style="margin-bottom: 10px">1、定义</div>
                  <div>{{ toast["其他渠道提供的营运资金"].定义 }}</div>
                </div>
                <div>
                  <div style="margin-bottom: 10px">2、来源</div>
                  <div>{{ toast["其他渠道提供的营运资金"].来源 }}</div>
                </div>
              </div>
            </div>
            <i class="el-tooltip el-icon-warning-outline item"></i>
          </el-tooltip>

          <el-input
            style="width: 308px; margin-right: 10px"
            placeholder="请填写"
            maxlength="20"
            id="other_working_capital"
            type="number"
            size="small"
            v-model="form_data.other_working_capital"
            @input="
              () => {
                setInputColor(`other_working_capital`);
              }
            "
          ></el-input>
          {{ unitObj[form_data.unit] }}
        </div>
      </div>
      <div style="display: flex; align-items: center">
        <div class="lable">
          现有流动资金贷款：<span class="year"
            >({{ form_data.year - 1 }}年)</span
          ><span style="color: #e02020">*</span>
        </div>
        <div class="left">
          <el-tooltip placement="top">
            <div slot="content">
              <div style="pading: 10px">
                <div style="margin-bottom: 10px">
                  <div style="margin-bottom: 10px">1、定义</div>
                  <div>{{ toast["现有流动资金贷款"].定义 }}</div>
                </div>

                <div>
                  <div style="margin-bottom: 10px">2、来源</div>
                  <div>{{ toast["现有流动资金贷款"].来源 }}</div>
                </div>
              </div>
            </div>
            <i class="el-tooltip el-icon-warning-outline item"></i>
          </el-tooltip>
          <el-input
            style="width: 308px; margin-right: 10px"
            maxlength="20"
            placeholder="请填写"
            v-model="form_data.cur_liquidity_loans"
            type="number"
            size="small"
            id="cur_liquidity_loans"
            @input="
              () => {
                setInputColor(`cur_liquidity_loans`);
              }
            "
          ></el-input>
          {{ unitObj[form_data.unit] }}
        </div>
      </div>
      <!-- <div class="row">
        <div class="lable">预测年份：</div>
        <div class="left" style="padding-left: 27px">
          <el-select disabled v-model="form_data.year" style="width: 100px">
            <el-option v-for="year in year_list" :value="year" :key="year"></el-option>
          </el-select>
        </div>
      </div> -->

      <!-- <div style="display: flex; align-items: center">
        <div class="lable">企业名称：</div>
        <div class="left" style="padding-left: 27px">
          <el-input
            style="width: 250px"
            disabled
            v-model="form_data.company_name"
          ></el-input>
        </div>
      </div> -->
      <div style="display: flex; align-items: center; width: 60%">
        <div class="lable">预计销售收入年增长率：</div>
        <div class="left">
          <el-tooltip placement="top">
            <div slot="content">
              <div style="pading: 10px">
                <div style="margin-bottom: 10px">
                  <div style="margin-bottom: 10px">1、定义</div>
                  <div style="max-width: 320px">
                    {{ toast["预计销售收入年增长率"].定义 }}
                  </div>
                </div>

                <div>
                  <div style="margin-bottom: 10px">2、来源</div>
                  <div>{{ toast["预计销售收入年增长率"].来源 }}</div>
                </div>
              </div>
            </div>
            <i class="el-tooltip el-icon-warning-outline item"></i>
          </el-tooltip>

          <el-radio
            v-model="form_data.annual_growth_operating_receipt_select"
            label="0"
            >以往年度收入增长率平均值</el-radio
          >
          <el-radio
            style="margin-left: 20px; padding-left: 0"
            v-model="form_data.annual_growth_operating_receipt_select"
            label="1"
          >
            自定义
            <span v-if="form_data.annual_growth_operating_receipt_select == 1">
              <el-input
                style="width: 112px; margin-right: 10px"
                type="number"
                size="small"
                id="annual_growth_operating_receipt"
                v-model="form_data.annual_growth_operating_receipt"
                @input="() => setInputColor('annual_growth_operating_receipt')"
              ></el-input>
              %
            </span>
          </el-radio>
        </div>
      </div>
      <!-- <div style="display: flex; align-items: center; width: 40%">
        <div style="width: 100px" class="lable">调整系数：</div>
        <div class="left">
          <el-tooltip placement="top">
            <div slot="content">
              <div style="pading: 10px; max-width: 320px">
                <div style="margin-bottom: 10px">
                  <div style="margin-bottom: 10px">1、定义</div>
                  <div>{{ toast["调整系数"].定义 }}</div>
                </div>

                <div>
                  <div style="margin-bottom: 10px">2、来源</div>
                  <div>{{ toast["调整系数"].来源 }}</div>
                </div>
              </div>
            </div>
            <i class="el-tooltip el-icon-warning-outline item"></i>
          </el-tooltip>

          <el-input
            style="width: 250px"
            placeholder="请填写"
            v-model="form_data.adj_factor"
            ref="adj_factor"
            maxlength="10"
          ></el-input>
        </div>
      </div> -->
      <!-- <div style="display: flex; align-items: center">
        <div class="lable">
          <span style="color: #e02020">*</span>现有流动资金贷款：<span class="year"
            >({{ form_data.year - 1 }}年)</span
          >
        </div>
        <div class="left">
          <el-tooltip placement="top">
            <div slot="content">
              <div style="pading: 10px; max-width: 320px">
                <div style="margin-bottom: 10px">
                  <div style="margin-bottom: 10px">1、定义</div>
                  <div>{{ toast["现有流动资金贷款"].定义 }}</div>
                </div>

                <div>
                  <div style="margin-bottom: 10px">2、来源</div>
                  <div>{{ toast["现有流动资金贷款"].来源 }}</div>
                </div>
              </div>
            </div>
            <i class="el-tooltip el-icon-warning-outline item"></i>
          </el-tooltip>
          <el-input
            style="width: 134px"
            maxlength="20"
            placeholder="请填写"
            v-model="form_data.cur_liquidity_loans"
            type="number"
            id="cur_liquidity_loans"
            @input="
              () => {
                setInputColor(`cur_liquidity_loans`);
              }
            "
          ></el-input
          >{{ unitObj[form_data.unit] }}
        </div>
      </div> -->

      <div style="width: 100%; justify-content: center; margin-top: 20px">
        <el-button
          style="width: 112px; height: 32px; padding: 0"
          type="primary"
          @click="start_test"
          >开始测算</el-button
        >
        <el-button
          style="
            width: 112px;
            height: 32px;
            padding: 0;
            border: 1px solid #3a85fd;
            color: #3a85fd;
          "
          @click="set_default_form_data"
          >重置</el-button
        >
      </div>
    </div>
    <div class="result">
      <div class="title">
        <div class="titles">
          <img src="@/assets/img/head/icon.png" style="margin-bottom: 5px" />
          流动资金额度测算结果
        </div>
        <div style="text-align: right; font-size: 14px; color: #333">
          测算结果类型
          <el-select
            style="width: 130px; font-size: 14px; color: #333"
            size="small"
            v-model="form_data.test_result"
          >
            <el-option
              v-for="item in test_result"
              :key="item.lable"
              :value="item.value"
              :label="item.lable"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div style="padding: 10px">
        <du-pont-analysis
          v-if="duBangData[form_data.test_result]"
          :dubang="duBangData[form_data.test_result]"
        >
        </du-pont-analysis>
        <div
          v-else
          style="
            text-align: center;
            font-size: 12px;
            color: #666666;
            min-height: 400px;
          "
        >
          <img src="@/assets/img/NewProject/nodata.svg" alt="" srcset="" />
          <div
            v-if="form_data.annual_growth_operating_receipt_select != '1'"
            style="margin-top: 10px"
          >
            暂无测算结果
          </div>
          <!-- <div v-else style="margin-top: 10px">您尚未自定义调整系数，请先自定义</div> -->
        </div>
      </div>
    </div>

    <div class="buttom">
      <div class="container">
        <el-button
          @click="up"
          style="border: 1px solid #3a85fd; color: #3a85fd"
        >
          上一步（调整财务数据）</el-button
        >
        <el-button type="primary" @click="next">重新测算</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { FinWhitewashCal } from "@/api.js";
import { rating, calculateHigh } from "@/api/common";
import { calculate } from "@/api/CapitalCalculation.js";
import * as sesstion from "@/store/localStorage";
// import driverStep from "./drivers.js"//步骤
export default {
  data() {
    return {
      form_data: {
        year: "",
        company_name: "",
        annual_growth_operating_receipt_select: "0",

        annual_growth_operating_receipt: "",
        cur_liquidity_loans: "",
        other_working_capital: "",
        adj_factor: "",
        test_result: "positive",
        company_code: "",
        report_type: "",
        unit: 1,
      },
      default_form_data: {
        annual_growth_operating_receipt_select: "0",

        annual_growth_operating_receipt: "",
        cur_liquidity_loans: "",
        other_working_capital: "",
        adj_factor: "",
        test_result: "positive",
        company_code: "",
        report_type: "",
      },
      toast: {
        预计销售收入年增长率: {
          定义: "即预测年份的销售收入年增长率。当用户提供4年及4年以上的财务数据时，我们以过去3年的平均年收入增长率为预计值；当用户提供4年以下的财务数据时，我们以过去所有年份的平均年收入增长率为预计值。",
          公式: "",
          来源: "公司财务报表或用户自行填入",
        },
        其他渠道提供的营运资金: {
          定义: "指用户发行的除贷款外的其他短期融资工具。需要用户根据企业实际情况手动填写。",
          公式: "",
          来源: "用户自行填入",
        },
        调整系数: {
          定义: "来源于银监会提出的保险系数，根据用户的实际情况和未来发展情况，在用户现有应收账款等科目的周转天数的基础上，通过调整系数进行合理的预测。",
          公式: "",
          来源: "模型测算",
        },
        现有流动资金贷款: {
          定义: "用户已经发生的流动资金贷款。不含用于置换或归还他行流贷部分和有追索权的票据贴现部分。需要用户利用人行征信系统查询到的企业流动资金贷款余额以及向企业问询后得到的其他信贷产品的融资数（比如贸易型企业用于日常生产经营的贸易融资额）。",
          公式: "",
          来源: "用户自行填入",
        },
      },

      test_result: [
        { lable: "乐观值", value: "positive" },
        { lable: "正常值", value: "normal" },
        { lable: "悲观值", value: "negative" },
        // { lable: "自定义调整系数", value: "customize" },
      ],
      duBangData: {},
      unitObj: {
        "ten-thousand": "万元",
        one: "元",
      },
    };
  },
  components: {
    STitle: require("@/components/title/title.vue").default,
    DuPontAnalysis:
      require("@/components/CapitalCalculation/DuPontAnalysis.vue").default,
  },
  created() {
    this.set_year_list();
    this.setData();

    this.$VM.CalculationResults = this;
  },
  mounted() {
    // 在mounted生命周期或方法中执行下述代码
    // this.start();
  },
  methods: {
    // 方法
    start() {
      this.$nextTick(() => {
        this.$driver.defineSteps(driverStep);
        this.$driver.start();
      });
    },

    search(param) {
      const company = sesstion.getLocalData("company");
      FinWhitewashCal({
        ...param,
        Stock_code: company.S_or_B_code ? company.S_or_B_code : "",

        company_name: company.S_or_B_code
          ? company.companyInfo.name
          : company.name,
      }).then((res) => {
        let safety_watch = res.data.safety_watch;
        let gaugeName = res.data.horsepower_watch;
        rating({
          year: param.year,

          report_time: param.report_time,
          company_name: param.name || param.company_name,
          company_code: param.company_code,
          safety_value: safety_watch,
          truth_value: gaugeName,
        }).then((res) => {
          setTimeout(() => {
            let reasonReportMapping = {
              "00": "年报",
              "01": "三季报",
              "02": "半年报",
              "03": "一季报",
              "001": "一月报",
              "002": "二月报",
              "003": "三月报",
              "004": "四月报",
              "005": "五月报",
              "006": "六月报",
              "007": "七月报",
              "008": "八月报",
              "009": "九月报",
              "010": "十月报",
              "011": "十一月报",
              "012": "十二月报",
            };
            this.$store.commit(
              "setYears",
              `(${param.year}${reasonReportMapping[param.report_time]})`
            );

            this.$VM.CompanyDetail.generalEvaluation = res.data.rating;
            sessionStorage.generalEvaluation = res.data.rating;
          }, 0);
        });
      });
    },
    set_default_form_data() {
      this.form_data = {
        ...this.form_data,
        ...this.default_form_data,
        company_code: sesstion.getLocalData("company").company_code,
        report_type: this.$VM.PublicSearch.form_data.report_type,
      };
    },
    next() {
      const company = sesstion.getLocalData("company");
      let params = { company_code: company.company_code };
      // console.log(params);
      var config = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization:
            "Bearer " + this.$store.state.isLogin.loginStore.auth_token,
        },
      };
      this.$axios
        .post(
          `${window.BASE_URL}/financing-calc/financing/clear-history/`,
          params,
          config
        )
        .then(({ data }) => {
          this.$router.push({ name: "DataEntry" });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    up() {
      this.$router.push({ name: "DataEntry", query: { status: 1 } });
    },
    setInputColor(id, border = `1px solid #d6dde4`) {
      $(`#${id}`).css({
        border: border,
      });
    },
    check() {
      if (
        this.form_data.annual_growth_operating_receipt_select == 1 &&
        this.form_data.annual_growth_operating_receipt === ""
      ) {
        this.setInputColor(
          "annual_growth_operating_receipt",
          `1px solid #fdbfb8`
        );
        this.$message.error("请输入预计销售收入年增长率");
        return 0;
      }
      if (this.form_data.adj_factor === "0") {
        this.$message.error("调整系数需大于0");
        return 0;
      }

      var data = [
        { key: "cur_liquidity_loans", name: "现有流动资金贷款" },
        { key: "other_working_capital", name: "其他渠道提供的营运资金" },
      ];

      let message = "";
      data.map((item) => {
        if (this.form_data[item.key] === "") {
          message += item.name + "、";
          this.setInputColor(item.key, `1px solid #fdbfb8`);
        }
      });
      if (message.length > 0) {
        message = message.substr(0, message.length - 1);
        message = "请填写" + message;
        this.$message.error(message);
        return 0;
      }
      return 1;
    },
    start_test() {
      if (!this.check()) return false;
      const annual_growth_operating_receipt =
        this.form_data.annual_growth_operating_receipt;
      calculate({
        ...this.form_data,
        annual_growth_operating_receipt: annual_growth_operating_receipt
          ? (annual_growth_operating_receipt / 100).toFixed(4)
          : annual_growth_operating_receipt,
      }).then(({ data }) => {
        this.duBangData = data;
      });
    },
    setData() {
      if (this.$route.query.status == 1) {
        var form_data = {};
        const { CapitalCalculation } = this.$store.state;
        Object.keys(this.form_data).map((key) => {
          form_data[key] = CapitalCalculation[key];
        });
        const annual_growth_operating_receipt =
          CapitalCalculation.input_factors.annual_growth_operating_receipt;
        this.form_data = {
          ...form_data,
          ...CapitalCalculation.input_factors,
          test_result: "normal",
          subjects: this.$store.state.DataEntry,
          annual_growth_operating_receipt: annual_growth_operating_receipt
            ? annual_growth_operating_receipt * 100
            : annual_growth_operating_receipt,
        };
        if (this.form_data.cur_liquidity_loans === undefined) {
          this.form_data.cur_liquidity_loans = "";
        }
        this.duBangData = CapitalCalculation.results;
        // console.log(this.form_data)
        // console.log({...form_data})
      } else {
        this.form_data = { ...this.form_data, ...this.$store.state.DataEntry };
      }
    },
    set_year_list() {
      var year_list = [];
      var year = new Date().getFullYear();
      for (let i = 0; i < 6; i++) {
        year_list.push(year--);
      }
      this.year_list = year_list;
    },
    getLen(str = "", hao = ".") {
      var len = 0;
      for (let i = 0; i < str.length; i++) {
        if (str[i] == hao) len++;
      }
      return len;
    },
  },
  watch: {
    "form_data.adj_factor": function (val, last) {
      if (val == "") return false;
      if (val.includes(".") && this.getLen(val) == 1) {
        if (+val == val) {
          return false;
        }
      }

      if (!/^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/.test(val)) {
        this.form_data.adj_factor = last;
      }
    },

    "form_data.annual_growth_operating_receipt": function (val, last) {
      if (val.length > 10) {
        this.form_data.annual_growth_operating_receipt = last;
      }
    },
    "form_data.cur_liquidity_loans": function (val, last) {
      if (val.length > 20) {
        this.form_data.cur_liquidity_loans = last;
      }
    },
    "form_data.other_working_capital": function (val, last) {
      if (val.length > 20) {
        this.form_data.other_working_capital = last;
      }
    },
  },
};
</script>
<style lang="less" scoped>
#CalculationResults {
  /deep/.el-input__inner {
    padding-right: 0;
  }

  margin-top: 10px;

  .title {
    padding: 20px;
    font-size: 20px;

    background: #fff;
    display: flex;
    justify-content: space-between;

    .titles {
      font-weight: 550;
      padding: 0px !important;
    }
  }

  .item {
    color: dodgerblue;
  }

  .form_data {
    display: flex;
    justify-content: space-between;
    background: #fff;
    flex-wrap: wrap;
    padding: 40px 40px 0px 40px;

    > div {
      margin-bottom: 20px;
      width: 49%;
      display: flex;
      align-items: center;

      .lable {
        width: 200px;
        text-align: right;
        position: relative;

        .year {
          position: absolute;
          right: 10px;
          bottom: 0;
          transform: translateY(100%);
          font-size: 12px;
          color: #999999;
        }
      }

      .left {
        display: flex;
        align-items: center;
        height: 32px;

        /deep/.el-radio {
          margin: 0;
        }

        .item {
          margin-right: 10px;
        }
      }
    }
  }

  .result {
    background: #fff;
    // padding-bottom: 50px;
    margin-top: 20px;
    margin-bottom: 70px;
  }

  .results {
    background: #fff;
    // padding-bottom: 50px;
    margin-top: 20px;
    margin-bottom: 70px;
  }

  .buttom {
    width: 100vw;
    background: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 60px;
    text-align: right;
    line-height: 60px;
  }
}
</style>

export default [
    {
        element: '#some-element1',
        popover: {
          title: '第一步',
          description: '选择想要预测的年份，下方会展示预测模型中所用到的财务数据，通常为预测年份前3年的财务数据。',
          position: 'right',
        }
    },
    {
        element: '#some-element2',
        popover: {
          title: '第二步',
          description: '默认为对应科目的财务数据，用户也可自行调整科目金额',
          position: 'bottom',
        }
    },
    {
        element: '#some-element3',
        popover: {
          title: '第三步',
          description: '点击“下一步”，跳转界面中，按照步骤填写"其他渠道提供的营运资金"和"现有流动资金货款"的金额后，点击“开始测算"即可生成预测结果',
          position: 'left',
        }
    },
]